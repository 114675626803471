import { useState } from 'react';

import { Link, Routes, Route } from 'react-router-dom';

import { Container } from '@mui/material';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import './App.css';

// ==============================================

const PDF = ({ file }) => {
  return (
    <>
      <iframe className="pdf" src={ file } title={ file } ></iframe>

      <a href={ file } download>
        Download {' '}

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"/>
          <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
        </svg>
      </a>
    </>
  );  
};

// ==============================================

const Image = ({ file, ratio }) => {
  return (    
    <>
      <div style={{
        backgroundImage: `url( "${file}" )`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        aspectRatio: ratio,
        marginBottom: '0.5rem',
      }}
      ></div>

      <a href="youtube-reviews.png" download>
        Download {' '}

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"/>
          <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
        </svg>
      </a>
    </>
  );
};

// ==============================================

const drawerWidth = 240;
// const navItems = ['Home', 'About', 'Contact'];
const navItems = [
  { title: 'MS Thesis', path: '/thesis' },
  { title: 'Journal Paper', path: '/paper' },
  { title: 'Conference Poster', path: '/poster' },
];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Josh Holloway
      </Typography>
      <Divider />
      <List>
        {navItems.map(({ title, path }, idx) => (
          <ListItem key={`nav-link-${idx}`} disablePadding>
            <Link to={path}>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={title} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      
      <CssBaseline />

      <AppBar className="appbar" component="nav">
        <Toolbar>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            Josh Holloway
          </Typography>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map(({ title, path }, idx) => (
              <Button key={`drawer-link-${idx}`} sx={{ color: '#fff' }}>
                <Link to={path}>
                  {title}
                </Link>
              </Button>
            ))}
          </Box>

        </Toolbar>
      </AppBar>
      
      <Box component="nav" >
        <Drawer
          className="drawer"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <Box component="main">
        <Toolbar />
      </Box>
    </Box>
  );
}

// ==============================================

function App() {
  return (
    <Container sx={{ height: '100vh' }}>

      <DrawerAppBar />

      <Routes>

        <Route path="/cert-a-plus"    element={ <PDF file='cert-a-plus.pdf'              /> } />
        <Route path="/thesis"         element={ <PDF file='thesis.pdf'         /> } />
        <Route path="/paper"          element={ <PDF file='paper.pdf'          /> } />
        <Route path="/poster"         element={ <PDF file='poster.pdf'         /> } />
        <Route path="/transcript-ms"  element={ <PDF file='transcript-ms.pdf'  /> } />
        <Route path="/transcript-phd" element={ <PDF file='transcript-phd.pdf' /> } />

        <Route path="/youtube-total"              element={ <Image file="youtube-stats-total--2023-03-07.png"         ratio="6524 / 3492" /> } />
        <Route path="/youtube-reviews"            element={ <Image file="youtube-reviews.png" ratio="3387 / 3028" /> } />
        <Route path="/youtube-cuda"               element={ <Image file="youtube-stats-cuda-2023-03-07.png" ratio="6474 / 3372" /> } />
        <Route path="/analytics-brookside-tattoo" element={ <Image file="google-analytics--brookside--2022-03-08.png" ratio="6536 / 3284" /> } />

      </Routes>

    </Container>
  );
}

// ==============================================

export default App;